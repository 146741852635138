'use client';

import React from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import { AppearInView } from '@/blocks/animations/AppearInView';
import { Container } from '@/blocks/components/Container';
import { ShowIntercomBtn } from '@/blocks/components/Intercom/Launchers';
import { HelpCenterLink } from '@/blocks/components/PlatformLink/HelpCenter';
import { WithClassName } from '@/blocks/components/types';
import { EventBlockNames } from '@/providers/analytics/types';
import links from '@/styles/link.module.scss';
import { getStringHtmlTagsValues } from '@/utils/string/getStringHtmlTagsValues';

import styles from './styles.module.scss';

export function HelpCenter(props: WithClassName) {
  const tr = useTranslations();

  return (
    <AppearInView disableScale>
      <p className={classNames(styles.ctaSectionFooterCaption, props.className)}>
        {tr.rich('sections.cta.footnoteChatHelp', {
          a1: (content) => (
            <ShowIntercomBtn
              data-event-label={getStringHtmlTagsValues(tr.raw('en.sections.cta.footnoteSecond')).a1}
              className={classNames(links.textLink, links.underline, links.noWrap)}
            >
              {content}
            </ShowIntercomBtn>
          ),
          a2: (content) => (
            <HelpCenterLink
              data-event-label={getStringHtmlTagsValues(tr.raw('en.sections.cta.footnoteChatHelp')).a2}
              className={classNames(links.textLink, links.underline, links.noWrap)}
            >
              {content}
            </HelpCenterLink>
          ),
        })}
      </p>
    </AppearInView>
  );
}

export function HelpCenterSection() {
  return (
    <Container tag={'section'} data-event-block-name={EventBlockNames.help_center}>
      <HelpCenter />
    </Container>
  );
}
