export enum HelpCenterArticle {
  ContestsQuickStart,
  RestrictedJurisdictions,
  InstallApp,
}

export const helpCenterArticles: Record<string, string> = {
  [HelpCenterArticle.ContestsQuickStart]: 'articles/9009066-how-to-trade-for-free-and-win-real-money',
  [HelpCenterArticle.RestrictedJurisdictions]: 'articles/8583030-restricted-jurisdictions',
  [HelpCenterArticle.InstallApp]: 'articles/10702042-how-to-download-and-install-the-primexbt-app',
};
