'use client';

import React from 'react';

import { useLocale } from 'next-intl';
import Image, { ImageProps } from 'next/image';

import { MobileStore } from '@/blocks/components/StoreButton/enums';

export function MobileStoreLogo({
  store,
  customLocale,
  theme,
  ...props
}: Omit<ImageProps, 'src' | 'alt'> & {
  store: MobileStore;
  customLocale?: string;
  theme: 'dark' | 'light' | 'white';
}) {
  const locale = useLocale();
  return (
    <Image
      src={`/assets/images/store-icons/${store}/${theme}/${customLocale || locale}.svg`}
      onError={(e) => {
        if (!e.currentTarget.src.endsWith('en.svg')) {
          e.currentTarget.src = `/assets/images/store-icons/${store}/${theme}/en.svg`;
        }
      }}
      alt={store}
      {...props}
    />
  );
}
