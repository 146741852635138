'use client';

import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';

import { ApiPlatform } from '@/api/types';
import { RewardCenterSection } from '@/blocks/components/PlatformLink/RewardCenterSection';
import { usePathname } from '@/i18n/routing';
import { filterObjNotNil } from '@/utils/obj';

type BuyCryptoProps = {
  from?: string;
  to?: string;
  amountFrom?: string | number;
  amountTo?: string | number;
};

function getInitProduct(pathname: string) {
  const params: Record<string, string> = {};

  if (pathname.startsWith('/crypto-futures')) {
    params['init-product'] = 'crypto-futures';
  } else if (pathname.startsWith('/cfd-trading')) {
    params['init-product'] = 'margin';
  } else if (pathname.startsWith('/promotions/trading-contests')) {
    params['init-product'] = 'contests';
  } else if (pathname.startsWith('/copy-trade')) {
    params['init-product'] = 'covesting';
  } else if (pathname.startsWith('/buy-crypto')) {
    params['init-product'] = 'buy-crypto';
  }

  return params;
}

export const usePlatformLink = () => {
  const { lang } = useParams();
  const pathname = usePathname();

  return {
    getSignupLink: (params?: Record<string, any>) => {
      return (
        `/id/sign-up?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getSignupAssetLink: (asset: any, params?: Record<string, any>) => {
      const assetParams: Record<string, string> = {};

      if (asset && (typeof asset?.name === 'string' || typeof asset === 'string')) {
        assetParams['asset'] = typeof asset.name === 'string' ? asset.name : asset;
      }

      // /id/sign-in?init-product=crypto-futures&redirect=/my/trade/futures&symbol={assetName}[&asset={assetName}]
      // /id/sign-in?init-product=margin&redirect=/my/trade/global&asset={assetName}

      if (asset && [asset?.platform, asset?._platform].includes(ApiPlatform.FX)) {
        assetParams['init-product'] = 'crypto-futures';
        assetParams['redirect'] = '/my/trade/futures';
        if (assetParams['asset']) {
          assetParams['symbol'] = assetParams['asset'];
        }
      } else if (asset && [asset?.platform, asset?._platform].includes(ApiPlatform.DEVEX)) {
        assetParams['init-product'] = 'margin';
        assetParams['redirect'] = '/my/trade/global';
      }

      return (
        `/id/sign-up?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...assetParams,
            ...params,
          }),
        ).toString()
      );
    },
    getSigninLink: (params?: Record<string, any>) => {
      return (
        `/id/sign-in?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getStrategyLink: (strategyId: number | string, params?: Record<string, any>) => {
      return (
        `/my/covesting/public-strategy/${strategyId}?` +
        new URLSearchParams(
          filterObjNotNil({
            _lang: lang as string,
            ...getInitProduct(pathname),
            ...params,
          }),
        ).toString()
      );
    },
    getBuyCryptoLink: (props?: BuyCryptoProps, params?: Record<string, any>) => {
      let url = `/my/buy-crypto`;

      const urlParams = new URLSearchParams(
        filterObjNotNil({
          _lang: lang as string,
          ...getInitProduct(pathname),
          ...params,
        }),
      );

      function validAmount(val: any): val is number | string {
        return typeof val === 'number' || val?.length;
      }

      if (props?.from && props?.to && (validAmount(props.amountFrom) || validAmount(props.amountTo))) {
        url += `/baksta/my/order`;
        urlParams.set('currencyFrom', props.from);
        urlParams.set('currencyTo', props.to);
        if (validAmount(props.amountFrom)) {
          urlParams.set('amountFrom', props?.amountFrom.toString());
        }
        if (validAmount(props.amountTo)) {
          urlParams.set('amountTo', props?.amountTo.toString());
        }
      }

      return url + '?' + new URLSearchParams(urlParams).toString();
    },
  };
};

export const LinkSignup: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { params?: Record<string, any> }
> = ({ params, className, ...props }) => {
  const { getSignupLink } = usePlatformLink();

  return (
    <Link href={getSignupLink(params)} className={classNames(className)} {...props}>
      {props.children}
    </Link>
  );
};

export const LinkAsset: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { asset: any; params?: Record<string, any> }
> = ({ params, className, asset, ...props }) => {
  const { getSignupAssetLink } = usePlatformLink();
  return (
    <Link href={getSignupAssetLink(asset, params)} className={classNames(className)} {...props}>
      {props.children}
    </Link>
  );
};

export const LinkRewardCenter: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & {
    params?: Record<string, any>;
    rewardCenterSection: RewardCenterSection;
  }
> = ({ params, rewardCenterSection, ...props }) => {
  return (
    <LinkSignup
      params={{
        ...params,
        redirect:
          rewardCenterSection === RewardCenterSection.WELCOME
            ? platformRewardsWelcomeBonusURL
            : platformRewardsURL,
      }}
      {...props}
    >
      {props.children}
    </LinkSignup>
  );
};

export const LinkSignin: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { params?: Record<string, any> }
> = ({ params, className, ...props }) => {
  const { getSigninLink } = usePlatformLink();

  return (
    <Link href={getSigninLink(params)} className={classNames(className)} {...props}>
      {props.children}
    </Link>
  );
};

export const LinkStrategy: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { strategyId: string | number }
> = ({ strategyId, className, children, ...other }) => {
  const { getStrategyLink } = usePlatformLink();
  return (
    <Link href={getStrategyLink(strategyId)} className={classNames(className)} {...other}>
      {children}
    </Link>
  );
};

/**
 * {from: string; to: string: amountFrom: string | number}
 * or
 * {from: string; to: string: amountTo: string | number}
 *
 * - not both amountTo and amountFrom
 * - either none or all params should be presented, only from or from/to not allowed, either none or all 3
 */
export function LinkBuyCrypto(
  props: React.HTMLAttributes<HTMLAnchorElement> & {
    params?: Record<string, any>;
  } & BuyCryptoProps,
) {
  const { getBuyCryptoLink } = usePlatformLink();
  const { children, params, className, from, to, amountFrom, amountTo, ...other } = props;
  return (
    <Link
      href={getBuyCryptoLink({ from, to, amountFrom, amountTo }, params)}
      className={classNames(className)}
      {...other}
    >
      {children}
    </Link>
  );
}

export function useSignup(): {
  openSignup: (params: Record<string, string>) => void;
  openSignupWithAsset: (args: { asset: any; params?: Record<string, any>; newTab?: boolean }) => void;
} {
  const noi18nRouter = useRouter();
  const { getSignupLink, getSignupAssetLink } = usePlatformLink();

  const openSignup = useCallback(
    (params: Record<string, string>) => {
      noi18nRouter.push(getSignupLink(params));
    },
    [getSignupLink, noi18nRouter],
  );

  const openSignupWithAsset = useCallback(
    ({ asset, params, newTab = false }: { asset: any; params?: Record<string, any>; newTab?: boolean }) => {
      if (newTab) {
        window.open(getSignupAssetLink(asset, params), '_blank');
      } else {
        noi18nRouter.push(getSignupAssetLink(asset, params));
      }
    },
    [getSignupAssetLink, noi18nRouter],
  );

  return useMemo(
    () => ({
      openSignup,
      openSignupWithAsset,
    }),
    [openSignup, openSignupWithAsset],
  );
}

// Its better to use LinkRewardCenter src/blocks/components/PlatformLink/index.tsx:124
export const platformRewardsURL = '/my/rewards/tasks';
// Its better to use LinkRewardCenter src/blocks/components/PlatformLink/index.tsx:124
export const platformRewardsWelcomeBonusURL = '/my/rewards/promo';
export const platformContestsURL = '/my/contests/all';
export const platformReferralURL = '/my/referral';
