'use client';

import React from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Dialog } from '@/blocks/components/Modal/Dialog';
import qr from '@/blocks/components/QrCode/qr.svg';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

type QrPopupProps = {
  isOpen: boolean;
  onClose(): void;
};

export const QrPopup = ({ isOpen, onClose }: QrPopupProps) => {
  const tr = useTranslations();

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} backdropBlurred className={styles.dialog}>
        <div className={styles.qrPopup}>
          <div className={styles.qrHeader}>
            <h2 className={typography.h5}>{tr.rich('footer.qrPopup.title')}</h2>
            <p className={typography.bodyM}>{tr.rich('components.mobileAppQr.hint')}</p>
          </div>
          <div className={styles.qrCorners}>
            <div className={classNames(styles.qrCode)}>
              <Image src={qr} alt={'qr code'} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
