'use client';

import React, { ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react';

import classNames from 'classnames';
import { useLocale } from 'next-intl';
import Image from 'next/image';
import Link, { LinkProps } from 'next/link';

import { useMobileStoreLink } from '@/blocks/components/PlatformLink/useMobileStoreLink';
import { OpenAPKModalButton } from '@/blocks/components/StoreButton/APKButtonModal/Modal';
import { MobileStoreLogo } from '@/blocks/components/StoreButton/MobileStoreLogo';
import { MobileStore } from '@/blocks/components/StoreButton/enums';
import { WithClassName } from '@/blocks/components/types';
import { downloadAppEventProps } from '@/providers/analytics/types';
import buttons from '@/styles/button.module.scss';

import styles from './StoreLink.module.scss';

const sizes = {
  s: styles.sizeS,
  m: styles.sizeM,
};

const storeButtonThemeVariants = {
  dark: styles.dark,
  light: styles.light,
};

export type StoreButtonProps<T extends ElementType> = PropsWithChildren<
  WithClassName & {
    variant?: keyof typeof storeButtonThemeVariants;
    size?: keyof typeof sizes;
    Component?: T;
    location?: string;
  }
> &
  ComponentPropsWithoutRef<T>;

function LinkMobile({ children, ...props }: PropsWithChildren<LinkProps>) {
  return (
    <Link {...props} target={'_blank'}>
      {children}
    </Link>
  );
}

export function GooglePlayButton<T extends ElementType>({
  children,
  variant = 'dark',
  size = 'm',
  // @ts-ignore
  Component = LinkMobile,
  className,
  location = 'page',
  ...rest
}: StoreButtonProps<T>) {
  const { googlePlay } = useMobileStoreLink({ location });

  const handleClick = () => {
    if (typeof rest.onClick === 'function') {
      rest.onClick();
    }
  };

  const ComponentElement = Component as ElementType;

  return (
    <ComponentElement
      className={classNames(styles.storeButton, storeButtonThemeVariants[variant], sizes[size], className)}
      href={googlePlay}
      {...rest}
      onClick={handleClick}
      data-event-name={downloadAppEventProps.event_name}
      data-event-category={downloadAppEventProps.event_category}
      data-event-subcategory={downloadAppEventProps.event_subcategory}
      data-event-action={downloadAppEventProps.event_action}
      data-event-label={downloadAppEventProps.label_google}
      data-event-location={location}
      data-event-include-url
    >
      <MobileStoreLogo
        store={MobileStore.GOOGLE_PLAY}
        theme={variant === 'dark' ? 'light' : 'dark'}
        width={118}
        height={30}
      />
    </ComponentElement>
  );
}

export function AppStoreButton<T extends ElementType>({
  children,
  variant = 'dark',
  size = 'm',
  // @ts-ignore
  Component = LinkMobile,
  className,
  location = 'page',
  ...rest
}: StoreButtonProps<T>) {
  const locale = useLocale();
  const { appStore } = useMobileStoreLink({ location });

  const handleClick = () => {
    if (typeof rest.onClick === 'function') {
      rest.onClick();
    }
  };

  const ComponentElement = Component as ElementType;

  return (
    <ComponentElement
      className={classNames(styles.storeButton, storeButtonThemeVariants[variant], sizes[size], className)}
      href={appStore}
      {...rest}
      onClick={handleClick}
      data-event-name={downloadAppEventProps.event_name}
      data-event-category={downloadAppEventProps.event_category}
      data-event-subcategory={downloadAppEventProps.event_subcategory}
      data-event-action={downloadAppEventProps.event_action}
      data-event-label={downloadAppEventProps.label_apple}
      data-event-location={location}
      data-event-include-url
    >
      <MobileStoreLogo
        store={MobileStore.APP_STORE}
        theme={variant === 'dark' ? 'light' : 'dark'}
        width={118}
        height={30}
      />
    </ComponentElement>
  );
}

export function APKButton<T extends ElementType>({
  children,
  variant = 'dark',
  size = 'm',
  // @ts-ignore
  Component = OpenAPKModalButton,
  className,
  location = 'page',
  onClick,
  ...rest
}: StoreButtonProps<T>) {
  const ComponentElement = Component as ElementType;
  return (
    <ComponentElement
      onClick={onClick}
      className={classNames(styles.storeButton, storeButtonThemeVariants[variant], sizes[size], className)}
      location={location}
      {...rest}
    >
      <Image
        src={`/assets/images/store-icons/apk/apk-${variant === 'dark' ? 'light' : 'dark'}.svg`}
        width={177}
        height={24}
        alt="Android APK"
      />
    </ComponentElement>
  );
}

export function APKButtonShort<T extends ElementType>({
  children,
  variant = 'dark',
  size = 'm',
  // @ts-ignore
  Component = OpenAPKModalButton,
  className,
  location = 'page',
  onClick,
  ...rest
}: StoreButtonProps<T>) {
  const ComponentElement = Component as ElementType;
  return (
    <ComponentElement
      onClick={onClick}
      className={classNames(buttons.btn, buttons.btnSocialL, buttons.btnSocialOutline, className)}
      location={location}
      {...rest}
    >
      <Image src={`/assets/images/store-icons/apk/apk.svg`} alt={'media icon'} width={24} height={24} />
    </ComponentElement>
  );
}
